var numeral = require('numeral');

export function currency(value) {
  return numeral(value).format('0,0') + '원';
}

export function currencyNum(value) {
  return numeral(value).format('0,0');
}

export function currencyKor(value, unit) {
  if (!unit) unit = '원';
  let val = parseInt(value);
  if (val === 0) return `0${unit}`;

  let txt = '';
  let max_unit = 1000000000000;

  let unitTextArray = ['조 ', '억 ', '만 '];
  let utIdx = 0;
  while (max_unit >= 10000) {
    let divideVal = Math.floor(val / max_unit);
    if (divideVal >= 1) {
      txt += numeral(divideVal).format('0,0') + unitTextArray[utIdx];
      val = val - divideVal * max_unit;
    }
    utIdx++;
    max_unit /= 10000;
  }
  if (val > 0 || val === '') {
    txt += numeral(val).format('0,0');
  } else if (val === 0) {
    let txtArray = txt.split('');
    txtArray.pop();
    txt = txtArray.join('');
  }
  return `${txt}${unit ? unit : ''}`;
}
