import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';
import createMutationsSharer from 'vuex-shared-mutations';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      reducer: (state) => ({
        user: state.user,
        trial: state.trial,
        prevPath: state.prevPath,
        basket: state.basket,
        productInquiry: state.productInquiry,
      }),
    }),
    createMutationsSharer({
      predicate: ['setUser', 'logOut', 'setTemporaryService', 'setInquiry'],
    }),
  ],
  state: {
    device: 'pc',
    user: {
      token: '',
      user_id: 0,
    },
    admin: {
      token: '',
      id: 0,
    },
    basket: {},
    inquiry: undefined,

    openLogin: false,
    // 로딩 상태값
    loadingStatus: true,
    loading: '',
    prevPath: '',
    accountDone: undefined,
    temporaryService: undefined,
    trial: false,
    userService: undefined, // 앱마켓 용. iframe으로 앱마켓 호출하는 서비스 정보
    filtered: [],
    search: undefined,
    productInquiry: undefined,
    templateCategory: undefined,
    subMessages: [],
  },
  mutations: {
    setSubMessages(state, value) {
      state.subMessages = value;
    },
    setProductInquiry(state, value) {
      state.productInquiry = value;
    },
    setFiltered(state, value) {
      state.filtered = value;
    },
    setTemplateCategory(state, value) {
      state.templateCategory = value;
    },
    setSearch(state, value) {
      state.search = value;
    },
    setUser(state, value) {
      state.user = value;
    },
    setTrial(state, value) {
      state.trial = value;
    },
    setAdmin(state, value) {
      state.admin = value;
    },
    setBasket(state, value) {
      state.basket = value;
    },
    setDevice(state, value) {
      state.device = value;
    },
    logOut(state) {
      state.user = {
        token: '',
        user_id: 0,
      };
      state.basket = {};
      state.trial = false;
    },
    setOpenLogin(state, value) {
      state.openLogin = value;
    },
    setLoading(state, value) {
      state.loadingStatus = value;
    },
    setLoadingText(state, value) {
      state.loading = value;
    },
    clearLoading(state) {
      state.loading = '';
    },
    setPrevPath(state, value) {
      state.prevPath = value;
    },
    clearBasket(state) {
      state.basket = {};
    },
    setAccountDone(state, value) {
      state.accountDone = value;
    },
    setTemporaryService(state, value) {
      state.temporaryService = value;
    },
    setUserService(state, value) {
      state.userService = value;
    },
    setInquiry(state, value) {
      state.inquiry = value;
    },
  },
  getters: {
    templateCategory: (state) => state.templateCategory,
    productInquiry: (state) => state.productInquiry,
    filtered: (state) => state.filtered,
    search: (state) => state.search,
    isLogin: (state) => state.user.user_id > 0,
    trial: (state) => state.trial,
    user: (state) => state.user,
    admin: (state) => state.admin,
    basket: (state) => state.basket,
    openLogin: (state) => state.openLogin,
    loading: (state) => state.loadingStatus,
    loadingText: (state) => state.loading,
    accountDone: (state) => state.accountDone,
    temporaryService: (state) => state.temporaryService,
    userService: (state) => state.userService,
    prevPath: (state) => state.prevPath,
    inquiry: (state) => state.inquiry,
    subMessages: (state) => state.subMessages,
  },
});
