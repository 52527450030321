<template>
  <div id="app">
    <router-view />
    <lp-toast></lp-toast>
    <loading v-if="loading.status" :message="loading.message"></loading>
  </div>
</template>

<script>
import Loading from './components/component/Loading';
import LpToast from './components/component/LpToast';

export default {
  name: 'App',
  components: { LpToast, Loading },
  data() {
    return {
      loading: {
        status: false,
        message: '',
      },
      excludePath: ['store'],
    };
  },
  created() {
    if (location.href.indexOf('www.') > -1) {
      location.replace(location.href.replace('www.', ''));
    }
  },

  watch: {
    '$route.path'(n) {
      if (location.href.indexOf('www.') > -1) {
        location.replace(location.href.replace('www.', ''));
      }
      if (n.indexOf('/mypage') > -1) {
        if (this.$store.getters.user.user_id === 0) {
          if (n.indexOf('contract') === -1) {
            this.$router.replace('/');
          } else {
            this.$store.commit('setPrevPath', this.$route.fullPath);
            this.toast('로그인이 필요합니다.');
            this.$router.replace('/signin');
          }
        }
      }
      setTimeout(() => {
        if (this.excludePath.indexOf(this.$route.path.substr(1, this.$route.path.length)) === -1) window.scrollTo(0, 0);
      }, 280);
    },
    '$store.getters.loadingText'(n) {
      this.loading.status = n !== '';
      this.loading.message = n;
    },
  },
};
</script>

<style>
#app {
  font-family: Inter, Noto Sans Korean;
}
</style>
